<template>
  <div class="flix-bg-warning flix-form-group flix-text-center">
    <h1 class="flix-html-h1">{{ total }} {{ $tc('message.message', total) }}</h1>
  </div>
</template>
<script>
export default {
  components: {},
  props: { total: Number }
}
</script>
<style lang="sass" scoped>
  .flix-bg-warning
    padding: 20px
    margin-top: -20px
</style>
